import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'

import CtaLogosGov from '../components/CtaLogosGov'
import CallOrangeIcon from '../components/CallOrangeIcon'
import Seo from '../components/Seo'
import Layout from '../components/Layout'
import '../styles/post.css'

const PostTemplate = ({ data, classes, pageContext }) => {

  return (
    <Layout>
      {pageContext.post_title !== 'SUD Videos' ? (
        <Seo title={`${pageContext.post_title}`} />
      ) : null}
      <div className="bodyPostTemplate postColorTemplate">
        <div className="row bg-mid-hero">
          <div class="col align-self-center">
            <h1
              className="title-post like-h3"
              dangerouslySetInnerHTML={{ __html: pageContext.post_title }}
            />
          </div>
        </div>

        <div class="row">
          <a class="skip-main" href="#main" role="none">
            Skip to main content
          </a>
          <div
            className="mt-4 content-post"
            dangerouslySetInnerHTML={{ __html: pageContext.post_content }}
          />
        </div>
      </div>
      <CtaLogosGov />
      <CallOrangeIcon />
    </Layout>
  )
}

export default PostTemplate
